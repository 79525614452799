import React from "react"
import Layout from "../components/layout" 
import Seo from "../components/seo"
import Lories from "../images/lories.png"
import LogoWhite from '../images/logo-white.svg';

const AboutUs = () => {
    return (
      <Layout>
        <Seo title="About Us" />
        <div className="py-10">
          <div className="container mx-auto">

            <div className="text-center">
                <h1 className="text-3xl font-bold mb-2 text-gameOnDarkGreen">
                About Us
                </h1>
                <p className="text-lg font-semibold mb-2 text-gameOnGreen my-5">
                When the game stops, it's not game over, but Game On!
                </p>
            </div>
            
            <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-10 mx-5 lg:container lg:w-full lg:mx-auto bg-gameOnDarkGreen px-5 lg:p-10 rounded-3xl my-10">

                <div className="py-5 lg:p-6 rounded-3xl">
                    <div className="">
                        <img src={Lories} alt="Lories Khoury" className="mb-4 mx-auto" />
                        <h3 className="text-white text-lg text-center font-semibold">Lories Khoury</h3>
                        <p className="text-gray-400 text-center">Founder / CEO</p>
                    </div>
                </div>

                <div className="lg:col-span-3 mt-4 text-left text-md font-medium my-10 text-white">
                    <img src={LogoWhite} className="mb-10 w-72" />
                    <p className="my-5">Game On! is a mental wellness app designed to connect you to expert mental wellness coaches and counselors. Through TheGameOnApp, individuals can access quality mental wellness services in a safe digital environment. Users are matched with professionals who understand their needs and are well-equipped to address them. You can sign up on Game On! without inputting any credit card information and receive a free consultation. Game On! offers different subscription options to make it easy and cost-effective for people to access quality mental wellness services. College athletes are offered a discount subscription when they sign up using their university email.</p>
                    <p className="my-5">Game On! App LLC, is founded by Lories Khoury, a Licensed Psychotherapist specializing in Sport Counseling. Lories has worked in mental health for over a decade, offering services to individuals, couples, children, and families at their homes, schools, clinics, and communities. Lories is a former athlete turned fitness enthusiast, who struggled with her mental health after a competition was over. She understands the stigma, both professionally and personally, of the impact mental health has on an athlete.</p>
                    <p className="my-5">Lories started Game On! because she is passionate about helping athletes like herself, as well as the general public, access the mental wellness support they seek privately and confidentially.</p>
                </div>

            </div>


            <div className="text-left lg:my-10 bg-gameOnLightGreen rounded-3xl mx-5 lg:mx-0 px-5 py-10 lg:p-14">
                <h2 className="text-3xl font-bold mb-2 text-gameOnDarkGreen">
                Mission Statement
                </h2>
                <p className="font-medium text-lg leading-6 lg:leading-8 lg:text-xl text-gray-600 mb-2 my-5">
                Our mission at Game On! is to connect athletes to vetted mental wellness coaches and counselors all over the nation through our mobile and web applications. Our motto at Game On! is, "When the game stops, it's not game over, but Game On!"
                </p>
            </div>

          </div>
        </div>
      </Layout>
    )
  }
  
  export default AboutUs